.homepage-description-block {
    display: flex;
    justify-content: center;

    &__content {
        max-width: 90%;
        text-align: center;

        p {
            line-height: 1.5;
        }

        &__button {
            background: $cl-green;
            font-weight: bold;
            border: none;
            margin-top: 14px;
        }
    }
}