$box-slider-point-size: 8px;
$box-slider-bottom-gap: 30px;

.box-slider {
	visibility: hidden;

	&.is-active {
		visibility: visible;
	}

	.list-products {
		flex-wrap: initial;
		column-gap: 0;
		row-gap: 0;
		justify-content: initial;
		align-items: initial;

		&__item {
			margin-right: 1.5rem;
		}
	}


	.splide__track {
		padding-bottom: 21px;
	}

	.splide__arrow {
		background: none;

		svg {
			width: 2rem;
			height: 2rem;
			fill: #878787;
		}
	}

	.splide__pagination {
		&__page {
			&.is-active {
				background: $cl-cta;
			}
		}
	}
}
