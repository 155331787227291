.homepage-articles-block {
    display: flex;

    @include query(870px) {
        flex-direction: column;
    }

    &__item {
        flex: 1;
        background: white;
        padding-bottom: 40px;
        margin-right: 14px;

        border-radius: $radius;

        &:last-of-type {
            margin-right: 0;
        }

        @include query(870px) {
            display: flex;
            margin-bottom: 2rem;
            margin-right: 0;
            padding-bottom: 0;
        }

        @include query(527px) {
            display: block;
        }

        &__image {
            img {
                max-width: 100%;
                border-top-left-radius: $radius !important;
                border-top-right-radius: $radius !important;

                @include query(870px) {
                    object-fit: cover;
                }
            }

            @include query(870px) {
                flex: 1;
            }

            @include query(670px) {
                flex: 2;
            }
        }

        &__content {
            margin-top: 40px;
            padding: 0 40px;

            @include query(870px) {
                border-right: none;
                margin-top: 0;
                padding: 30px;
                flex: 2;
            }
        }

        &__title {
            font-size: 24px;
            font-weight: bold;
            max-width: 70%;
            line-height: 1.3;
            margin-bottom: 27px;
            color: #1b1a1a;

            @include query(1110px) {
                max-width: 100%;
            }
        }

        &__summary {
            margin-bottom: 27px;

            p {
                color: #b2b2b2;
                font-weight: normal;
                line-height: 1.5;
                font-size: 15px;
            }
        }

        &__action {
            background: $cl-green;
            border: none;
        }

        &:last-of-type {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;

            .homepage-articles-block__item__image {
                img {
                    border-top-right-radius: $radius;
                }
            }

            .homepage-articles-block__item__content {
                border-right: none;
            }
        }
    }


}