.list-products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    row-gap: 10px;
    column-gap: 10px;

    @include reset-ul();

    &__item {
        display: flex;
        flex-direction: column;
        width: calc(100% /  4 - 30px / 4);
        padding: 0 0 14px;

        border-radius: $radius;

        transition: all .3s $transition;

        @include query(800px) {
            width: calc(100% / 3 - 20px / 2);
        }

        @include query(600px) {
            width: calc(100% / 2 - 10px / 1);
        }

        @include query(410px) {
            width: 100%;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        }

        &__link {
            display: flex;
            flex-direction: column;

            flex: 1;

            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &__image {
            display: flex;

            position: relative;
            height: auto;

            justify-content: center;
            align-items: center;

            &::before {
                content: '';
                padding-top: 150%;
            }

            &__element {
                max-height: 100%;
                max-width: 100%;
                border-radius: $radius;
                width: 100%;
                height: auto;
            }
        }

        &__info {
            text-align: center;

            display: flex;
            flex-direction: column;
            padding: 0 10px;
            flex: 1;

            &__title {
                display: block;
                @include font-size(18px);
                line-height: 1.2;
                margin-bottom: 7px;
                font-weight: 400;
                color: #000;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: .37px;

                span {
                    display: block;
                    @include font-size(14px);
                    text-transform: none;
                    opacity: 75%;
                    text-align: center;
                    margin-top: 7px;
                }
            }

            &__prices {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                flex: 1;
                align-content: flex-end;

                gap: 10px;

                &__price {
                    @include font-size(16px);
                    margin-bottom: 10px;

                    &--old {
                        line-height: 28px;
                        color: #8a8a8a;
                        text-decoration: line-through;
                    }

                    &--main {
                        @include font-size(18px);
                         font-weight: 700;
                    }
                }
            }

            &__availability {
                margin: 10px 0;
                color: #3ea938;
                @include font-size(12px);
                font-weight: 400;
                display: flex;
                justify-content: center;
                letter-spacing: .37px;

                &__text {
                    display: block;
                    line-height: 18px;
                    @include font-size(12px);
                    color: #3ea938;
                    font-weight: 700;
                    text-align: center;
                    max-width: 70%;

                    &__date {
                        display: block;
                        @include font-size(11px);
                        font-weight: 400;
                        color: #333;
                    }
                }
            }
        }

        &__button {
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            transition: all .3s $transition;

            .list-products__item:hover & {
                opacity: 1;
            }
        }
    }
}