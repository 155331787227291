.box-slider {
  visibility: hidden;
}
.box-slider.is-active {
  visibility: visible;
}
.box-slider .list-products {
  flex-wrap: initial;
  column-gap: 0;
  row-gap: 0;
  justify-content: initial;
  align-items: initial;
}
.box-slider .list-products__item {
  margin-right: 1.5rem;
}
.box-slider .splide__track {
  padding-bottom: 21px;
}
.box-slider .splide__arrow {
  background: none;
}
.box-slider .splide__arrow svg {
  width: 2rem;
  height: 2rem;
  fill: #878787;
}
.box-slider .splide__pagination__page.is-active {
  background: #65BB51;
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Noto Sans"), url("../../../../public/frontend/fonts/noto-sans-v36-latin_latin-ext-800.woff2") format("woff2");
}
@font-face {
  font-family: "Noto Sans-fallback";
  size-adjust: 106.62%;
  ascent-override: 97%;
  src: local("Arial");
}
@font-face {
  font-family: "Qwigley";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Qwigley"), url("../../../../public/frontend/fonts/qwigley-v18-latin_latin-ext-regular.woff2") format("woff2");
}
@font-face {
  font-family: "Qwigley-fallback";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  size-adjust: 55.31%;
  ascent-override: 200%;
  src: local("Arial");
}
.slider-block {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 20px auto;
  padding: 0;
  justify-content: center;
}
.slider-block > .slide:not(:first-child) {
  display: none;
}
@media screen and (max-width: 37.4375em) {
  .slider-block {
    flex-direction: column;
  }
}
.slider-block__in {
  background: #d9e5d7;
  height: auto;
  border-radius: 5px;
}
.slider-block__item {
  display: flex !important;
  float: left;
  position: relative;
  height: 480px;
  width: 100%;
}
@media screen and (max-width: 58.6875em) {
  .slider-block__item {
    height: auto;
  }
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item {
    flex-direction: column;
  }
}
.slider-block__item__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 60px;
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item__content {
    padding: 21px;
  }
}
.slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
  display: block;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1;
  text-align: left;
  border: none;
  font-weight: 800;
  color: #25421E;
  font-size: calc(1.525rem + 3.3vw);
  font-family: "Noto Sans", "Noto Sans-fallback", serif;
}
@media (min-width: 1200px) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 58.6875em) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: calc(1.425rem + 2.1vw);
  }
}
@media screen and (max-width: 58.6875em) and (min-width: 1200px) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: calc(1.3625rem + 1.35vw);
    text-align: center;
  }
}
@media screen and (max-width: 37.4375em) and (min-width: 1200px) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: 2.375rem;
  }
}
@media screen and (max-width: 29.9375em) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: calc(1.3375rem + 1.05vw);
  }
}
@media screen and (max-width: 29.9375em) and (min-width: 1200px) {
  .slider-block__item__content h1, .slider-block__item__content h2, .slider-block__item__content h3, .slider-block__item__content h4, .slider-block__item__content h5, .slider-block__item__content h6 {
    font-size: 2.125rem;
  }
}
.slider-block__item__content h1 i, .slider-block__item__content h1 em, .slider-block__item__content h2 i, .slider-block__item__content h2 em, .slider-block__item__content h3 i, .slider-block__item__content h3 em, .slider-block__item__content h4 i, .slider-block__item__content h4 em, .slider-block__item__content h5 i, .slider-block__item__content h5 em, .slider-block__item__content h6 i, .slider-block__item__content h6 em {
  font-family: "Qwigley", "Qwigley-fallback", sans-serif;
  text-transform: lowercase;
  display: block;
  font-weight: 400;
  padding-left: 16%;
  font-style: normal;
  margin-top: -7px;
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item__content h1 i, .slider-block__item__content h1 em, .slider-block__item__content h2 i, .slider-block__item__content h2 em, .slider-block__item__content h3 i, .slider-block__item__content h3 em, .slider-block__item__content h4 i, .slider-block__item__content h4 em, .slider-block__item__content h5 i, .slider-block__item__content h5 em, .slider-block__item__content h6 i, .slider-block__item__content h6 em {
    text-align: center;
  }
}
.slider-block__item__content__buttons {
  margin-top: 36px;
  display: flex;
}
@media screen and (max-width: 58.6875em) {
  .slider-block__item__content__buttons {
    flex-direction: column;
  }
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item__content__buttons {
    margin-top: 14px;
    width: 100%;
    flex-direction: column;
  }
}
.slider-block__item__content__buttons__button {
  display: inline-flex;
  padding: 12px 36px 12px 21px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-weight: 600;
  align-items: center;
}
@media screen and (max-width: 58.6875em) {
  .slider-block__item__content__buttons__button {
    width: 100%;
    flex-basis: 100%;
  }
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item__content__buttons__button {
    font-size: 16px;
  }
}
@media screen and (max-width: 29.9375em) {
  .slider-block__item__content__buttons__button {
    font-size: 14px;
  }
}
.slider-block__item__content__buttons__button::before {
  display: inline-block;
  margin-right: 14px;
  font-weight: normal;
}
.slider-block__item__content__buttons__button.is-outside {
  margin-right: 7px;
}
@media screen and (max-width: 58.6875em) {
  .slider-block__item__content__buttons__button.is-outside {
    margin-bottom: 7px;
    margin-right: 0;
  }
}
.slider-block__item__content__buttons__button.is-outside::before {
  content: url("https://api.iconify.design/system-uicons/sun.svg");
}
.slider-block__item__content__buttons__button.is-inside::before {
  content: url("https://api.iconify.design/uil/home-alt.svg");
}
.slider-block__item__content__buttons__button.is-link::before {
  content: url("https://api.iconify.design/ic/twotone-arrow-right-alt.svg");
}
.slider-block__item__image {
  flex: 1;
}
.slider-block__item__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen and (max-width: 37.4375em) {
  .slider-block__item__image img {
    order: -1;
    min-height: 250px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
.slider-block .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 60px;
  font-size: 0;
  z-index: 30;
  transition: color 0.125s ease;
  border: none;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}
@media screen and (max-width: 37.4375em) {
  .slider-block .slick-arrow {
    height: 40px;
    width: 40px;
  }
}
.slider-block .slick-arrow::before {
  font-size: 2.5rem;
  color: #35291b;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6980392157);
}
.slider-block .slick-arrow:hover {
  box-shadow: 0 8px 20px 0 rgba(176, 190, 197, 0.3);
}
.slider-block .slick-prev {
  left: -30px;
}
@media screen and (max-width: 37.4375em) {
  .slider-block .slick-prev {
    left: -20px;
  }
}
.slider-block .slick-prev::before {
  content: url("https://api.iconify.design/mdi/arrow-left-thin.svg");
}
.slider-block .slick-next {
  right: -30px;
}
@media screen and (max-width: 37.4375em) {
  .slider-block .slick-next {
    right: -20px;
  }
}
.slider-block .slick-next::before {
  content: url("https://api.iconify.design/mdi/arrow-right-thin.svg");
}

.homepage-articles-block {
  display: flex;
}
@media screen and (max-width: 54.375em) {
  .homepage-articles-block {
    flex-direction: column;
  }
}
.homepage-articles-block__item {
  flex: 1;
  background: white;
  padding-bottom: 40px;
  margin-right: 14px;
  border-radius: 5px;
}
.homepage-articles-block__item:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 54.375em) {
  .homepage-articles-block__item {
    display: flex;
    margin-bottom: 2rem;
    margin-right: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 32.9375em) {
  .homepage-articles-block__item {
    display: block;
  }
}
.homepage-articles-block__item__image img {
  max-width: 100%;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
@media screen and (max-width: 54.375em) {
  .homepage-articles-block__item__image img {
    object-fit: cover;
  }
}
@media screen and (max-width: 54.375em) {
  .homepage-articles-block__item__image {
    flex: 1;
  }
}
@media screen and (max-width: 41.875em) {
  .homepage-articles-block__item__image {
    flex: 2;
  }
}
.homepage-articles-block__item__content {
  margin-top: 40px;
  padding: 0 40px;
}
@media screen and (max-width: 54.375em) {
  .homepage-articles-block__item__content {
    border-right: none;
    margin-top: 0;
    padding: 30px;
    flex: 2;
  }
}
.homepage-articles-block__item__title {
  font-size: 24px;
  font-weight: bold;
  max-width: 70%;
  line-height: 1.3;
  margin-bottom: 27px;
  color: #1b1a1a;
}
@media screen and (max-width: 69.375em) {
  .homepage-articles-block__item__title {
    max-width: 100%;
  }
}
.homepage-articles-block__item__summary {
  margin-bottom: 27px;
}
.homepage-articles-block__item__summary p {
  color: #b2b2b2;
  font-weight: normal;
  line-height: 1.5;
  font-size: 15px;
}
.homepage-articles-block__item__action {
  background: #407b32;
  border: none;
}
.homepage-articles-block__item:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.homepage-articles-block__item:last-of-type .homepage-articles-block__item__image img {
  border-top-right-radius: 5px;
}
.homepage-articles-block__item:last-of-type .homepage-articles-block__item__content {
  border-right: none;
}

.homepage-description-block {
  display: flex;
  justify-content: center;
}
.homepage-description-block__content {
  max-width: 90%;
  text-align: center;
}
.homepage-description-block__content p {
  line-height: 1.5;
}
.homepage-description-block__content__button {
  background: #407b32;
  font-weight: bold;
  border: none;
  margin-top: 14px;
}

.homepage-section-block {
  padding: 70px 0 50px;
}
@media screen and (max-width: 37.4375em) {
  .homepage-section-block {
    padding: 35px 0 20px;
  }
}
.homepage-section-block--small-padding {
  padding: 35px 0 20px;
}
.homepage-section-block--colored {
  background: #f3f3f3;
}
.homepage-section-block__title {
  text-align: center;
  color: #1b1a1a;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.splide[unbounded]:has(.splide__arrows) {
  padding-left: 2.7rem;
  padding-right: 2.7rem;
}
.splide__arrow--prev {
  left: 0;
}
.splide__arrow--next {
  right: 0;
}
.splide:not([unbounded]) .splide__arrow--prev {
  left: auto;
  right: calc(100% + 1rem);
}
.splide:not([unbounded]) .splide__arrow--next {
  left: calc(100% + 1rem);
  right: auto;
}
.splide__pagination:not(.splide__pagination--ttb) {
  bottom: auto;
  top: calc(100% + 0.5rem);
}
.splide__pagination__page.is-active {
  background: var(--aui-color-primary);
}

.list-flags {
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-flags__item {
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 1.1;
  text-align: center;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  padding: 6px 7px;
  word-break: break-all;
  letter-spacing: 0.37px;
}

.list-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  row-gap: 10px;
  column-gap: 10px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-products__item {
  display: flex;
  flex-direction: column;
  width: calc(25% - 7.5px);
  padding: 0 0 14px;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.61, 0.2, 0.48, 1.01);
}
@media screen and (max-width: 50em) {
  .list-products__item {
    width: calc(33.3333333333% - 10px);
  }
}
@media screen and (max-width: 37.5em) {
  .list-products__item {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 25.625em) {
  .list-products__item {
    width: 100%;
  }
}
.list-products__item:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.list-products__item__link {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;
}
.list-products__item__link:hover {
  text-decoration: none;
}
.list-products__item__image {
  display: flex;
  position: relative;
  height: auto;
  justify-content: center;
  align-items: center;
}
.list-products__item__image::before {
  content: "";
  padding-top: 150%;
}
.list-products__item__image__element {
  max-height: 100%;
  max-width: 100%;
  border-radius: 5px;
  width: 100%;
  height: auto;
}
.list-products__item__info {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  flex: 1;
}
.list-products__item__info__title {
  display: block;
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 7px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.37px;
}
.list-products__item__info__title span {
  display: block;
  font-size: 0.875rem;
  text-transform: none;
  opacity: 75%;
  text-align: center;
  margin-top: 7px;
}
.list-products__item__info__prices {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-content: flex-end;
  gap: 10px;
}
.list-products__item__info__prices__price {
  font-size: 1rem;
  margin-bottom: 10px;
}
.list-products__item__info__prices__price--old {
  line-height: 28px;
  color: #8a8a8a;
  text-decoration: line-through;
}
.list-products__item__info__prices__price--main {
  font-size: 1.125rem;
  font-weight: 700;
}
.list-products__item__info__availability {
  margin: 10px 0;
  color: #3ea938;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  letter-spacing: 0.37px;
}
.list-products__item__info__availability__text {
  display: block;
  line-height: 18px;
  font-size: 0.75rem;
  color: #3ea938;
  font-weight: 700;
  text-align: center;
  max-width: 70%;
}
.list-products__item__info__availability__text__date {
  display: block;
  font-size: 0.6875rem;
  font-weight: 400;
  color: #333;
}
.list-products__item__button {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: all 0.3s cubic-bezier(0.61, 0.2, 0.48, 1.01);
}
.list-products__item:hover .list-products__item__button {
  opacity: 1;
}