.list-flags {
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  @include reset-ul();

  &__item {
    text-transform: uppercase;
    @include font-size(10px);
    line-height: 1.1;
    text-align: center;
    border-radius: $radius;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    justify-content: center;
    padding: 6px 7px;
    word-break: break-all;
    letter-spacing: .37px;
  }
}