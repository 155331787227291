.homepage-section-block {
    padding: 70px 0 50px;

    @include query($grid-m) {
        padding: 35px 0 20px;
    }

    &--small-padding {
        padding: 35px 0 20px;
    }

    &--colored {
        background: #f3f3f3;
    }

    &__title {
        text-align: center;
        color: #1b1a1a;
    }
}