@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Noto Sans'),
    url('../../../../public/frontend/fonts/noto-sans-v36-latin_latin-ext-800.woff2') format('woff2')
}

@font-face {
    font-family: "Noto Sans-fallback";
    size-adjust: 106.62%;
    ascent-override: 97%;
    src: local("Arial");
}

@font-face {
    font-family: 'Qwigley';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Qwigley'),
    url('../../../../public/frontend/fonts/qwigley-v18-latin_latin-ext-regular.woff2') format('woff2')
}

@font-face {
    font-family: 'Qwigley-fallback';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    size-adjust: 55.31%;
    ascent-override: 200%;
    src: local('Arial')
}

.slider-block {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto 20px auto;
    padding: 0;

    justify-content: center;

    > .slide:not(:first-child) {
        display: none;
    }

    @include query($grid-m) {
        flex-direction: column;
    }

    &__in {
        background: #d9e5d7;
        height: auto;
        border-radius: $radius;
    }

    &__item {
        display: flex !important;
        float: left;
        position: relative;

        height: 480px;

        width: 100%;

        @include query($grid-l) {
            height: auto;
        }

        @include query($grid-m) {
            flex-direction: column;
        }

        &__content {
            flex: 1;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 60px;

            @include query($grid-m) {
                padding: 21px;
            }

            h1, h2, h3, h4, h5, h6 {
                display: block;
                width: 100%;
                margin-top: 0;
                margin-bottom: 10px;
                line-height: 1;

                text-align: left;

                border: none;
                font-weight: 800;

                color: #25421E;

                @include font-size(64px);

                font-family: 'Noto Sans', 'Noto Sans-fallback', serif;


                @include query($grid-l) {
                    @include font-size(48px);
                }

                @include query($grid-m) {
                    @include font-size(38px);
                    text-align: center;
                }

                @include query($grid-s) {
                    @include font-size(34px);
                }

                i, em {
                    font-family: 'Qwigley', 'Qwigley-fallback', sans-serif;

                    text-transform: lowercase;
                    display: block;
                    font-weight: 400;
                    padding-left: 16%;
                    font-style: normal;

                    margin-top: -7px;

                    @include query($grid-m) {
                        text-align: center;
                    }

                }
            }

            &__buttons {
                margin-top: 36px;
                display: flex;

                @include query($grid-l) {
                    flex-direction: column;
                }

                @include query($grid-m) {
                    margin-top: 14px;
                    width: 100%;
                    flex-direction: column;
                }

                &__button {
                    display: inline-flex;
                    padding: 12px 36px 12px 21px;

                    border: 2px solid rgba(0, 0, 0, 0.5);
                    border-radius: $radius;

                    font-weight: 600;

                    align-items: center;


                    @include query($grid-l) {
                        width: 100%;
                        flex-basis: 100%;
                    }

                    @include query($grid-m) {
                        font-size: 16px;
                    }

                    @include query($grid-s) {
                        font-size: 14px;
                    }

                    &::before {
                        display: inline-block;
                        margin-right: 14px;
                        font-weight: normal;
                    }

                    &.is-outside {
                        margin-right: 7px;

                        @include query($grid-l) {
                            margin-bottom: 7px;
                            margin-right: 0;
                        }
                    }

                    &.is-outside::before {
                        content: url('https://api.iconify.design/system-uicons/sun.svg');
                    }

                    &.is-inside::before {
                        content: url('https://api.iconify.design/uil/home-alt.svg');
                    }

                    &.is-link::before {
                        content: url('https://api.iconify.design/ic/twotone-arrow-right-alt.svg');
                    }
                }
            }
        }

        &__image {
            flex: 1;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                border-top-right-radius: $radius;
                border-bottom-right-radius: $radius;

                @include query($grid-m) {
                    order: -1;
                    min-height: 250px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: $radius;
                    border-bottom-left-radius: $radius;
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 60px;
        width: 60px;
        font-size: 0;
        z-index: 30;
        transition: color .125s ease;

        border: none;
        border-radius: 50%;
        background: #fff;
        text-align: center;

        @include query($grid-m) {
            height: 40px;
            width: 40px;
        }

        &::before {
            font-size: 2.5rem;
            color: #35291b;
            text-shadow: 1px 1px 1px #FFFFFFB2;
        }

        &:hover {
            box-shadow: 0 8px 20px 0 rgba(176,190,197,.3);
        }
    }

    .slick-prev {
        left: -30px;

        @include query($grid-m) {
            left: -20px;
        }

        &::before {
            content: url('https://api.iconify.design/mdi/arrow-left-thin.svg');
        }
    }

    .slick-next {
        right: -30px;

        @include query($grid-m) {
            right: -20px;
        }

        &::before {
            content: url('https://api.iconify.design/mdi/arrow-right-thin.svg');
        }
    }
}
